import './App.css';
import Cabecalho from './componentes/Cabecalho';
import Container from 'react-bootstrap/Container';
import { useEffect, useRef, useState } from 'react';
import Mapa from './componentes/Mapa';

import {ThemeProvider} from "styled-components";
import { GlobalStyles } from "./componentes/Globalstyle";
import { lightTheme, darkTheme } from "./componentes/Themes"

import {
  Routes, //replaces "Switch" used till v5
  Route,
  useNavigate,
  useLocation
} from "react-router-dom";
import Login from './componentes/Login';
import { useTranslation } from 'react-i18next';
import NotFound from './componentes/NotFound/NotFound';

import { TemaContext } from './common/context/TemaContext';
import { LinguagemContext } from './common/context/LinguagemContext';
import { UsuarioContext } from './common/context/UsuarioContext';
import Sankey from './componentes/Sankey';

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  const { i18n } = useTranslation();

  const [language, setLanguage] = useState(localStorage.hasOwnProperty("language") ? 'en' : localStorage.getItem("language"));

  const [theme, setTheme] = useState(localStorage.hasOwnProperty("theme") ? 'dark' : localStorage.getItem("theme"));
  
  const [logado, setLogado] = useState(localStorage.hasOwnProperty("logado") ? false : localStorage.getItem("logado"));
  
  useEffect(() => {
    if (
      localStorage.getItem('theme') === 'light' ||
      (!('theme' in localStorage) &&
        window.matchMedia('(prefers-color-scheme: light)').matches)
    ) 
      {
        setTheme('light')
      } else {
        setTheme('dark')
      }

    if (
      localStorage.getItem('language') === 'pt' ||
      (!('language' in localStorage))
    ) 
      {
        setLanguage('pt');
        i18n.changeLanguage('pt-BR');
      } else {
        setLanguage('en');
        i18n.changeLanguage('en-US');
      }

}, [])

  return (
    <div className={location.pathname === "/old" ? 'imagem_bg' : 'bg'} >
        <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
        <>
          <UsuarioContext.Provider value={{logado, setLogado}}>
            <TemaContext.Provider value={{theme, setTheme}}>
              <LinguagemContext.Provider value={{language, setLanguage}}>
                <Cabecalho origem = {location.pathname} ocultaLinks = {location.pathname === "/mapa" || location.pathname === "/login" || location.pathname === "/mapa2" || location.pathname === "/sankey" || location.pathname === "/"}></Cabecalho>
                <br></br>
                <br></br>
                <Container className="App">
                    <GlobalStyles/>
                      <Routes>
                         {/*<Route path="/" element={<HomeScreen language = {language} theme = {theme} />} />*/}
                          
                          <Route path="/" element={<Login language = {language}/>} />
                          <Route path='/sankey' element={<Sankey language = {language}/>}/>
                          <Route path="/mapa" element={<Mapa />} />
                          <Route path='*' element={<NotFound/>} />
                      </Routes>    
                </Container>
              </LinguagemContext.Provider>
            </TemaContext.Provider> 
          </UsuarioContext.Provider>
        </>
      </ThemeProvider> 
    </div>
  );
}

export default App;
