import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { LinguagemContext } from '../../common/context/LinguagemContext';
// useTranslation é um hook 
// que devolve uma função de tradução (t) e a instância do i18n

const I18n = ({theme}) => {
  const {language, setLanguage} = useContext(LinguagemContext);  

  const { i18n } = useTranslation()
  // Instância do i18n

  function handleChangeLanguage(new_language) {
    // Trocando o idioma na chamada da função
    i18n.changeLanguage(new_language);
    if(new_language === 'en-US'){
        
        localStorage.setItem("language","en");
    
        setLanguage("en");
    }
    else if(new_language === 'pt-BR'){
        localStorage.setItem("language","pt");
        setLanguage("pt")
    };

  }

  //const selectedLanguage = i18n.language // Idioma selecionado
  return (
    <div style={{maxWidth: "40px"}}>
        <div className="lang-menu">
            <div className={theme === "dark" ? "selected-lang " + language + " inverter" : "selected-lang " + language}>
            
            </div>
            <ul>
                <li>
                    <a onClick={() => handleChangeLanguage('en-US')} href="#" className="en">English</a>
                </li> 
                <li>
                    <a onClick={() => handleChangeLanguage('pt-BR')} href="#" className="pt">Português</a>
                </li>
            </ul>
        </div>  
    </div>
  )
}

export default I18n